import React from "react";
import ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import { Home } from "./pages/Home";
import "./styles/Index.scss";
import { Landing } from "./pages/Landing";
import { Impressum } from "./pages/Impressum";
import { DataProtection } from "./pages/DataProtection";
import { Contact } from "./pages/Contact";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path="/" element={<Home />} />
      <Route path="/Kühlzelle" element={<Landing type="Kühlzelle" />} />
      <Route path="/Kühlmöbel" element={<Landing type="Kühlmöbel" />} />
      <Route path="/Ladenbau" element={<Landing type="Ladenbau" />} />
      <Route path="/Impressum" element={<Impressum />} />
      <Route path="/Datenschutzerklärung" element={<DataProtection />} />
      <Route path="/Kontakt" element={<Contact />} />
    </Route>
  )
);

root.render(<RouterProvider router={router} />);
