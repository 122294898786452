import { FC, useState } from "react";
import "../styles/Landing.scss";
import { Main } from "../components/Main";
import { Collage } from "../components/Collage";
import { ReactComponent as OvalSVG } from "../assets/Oval.svg";
import { ReactComponent as BackSVG } from "../assets/Back.svg";
import { Services } from "../components/Services";
import { Footer } from "../components/Footer";
import { Link } from "react-router-dom";
import { Feedback } from "../components/Feedback";
import { Header } from "../components/Header";

export const Landing: FC<{ type: string }> = ({ type }) => {
  const [isOpenCollage, setIsOpenCollage] = useState<boolean>(false);

  return (
    <>
      <Header home={false} />
      <section id="landing">
        <Main type={type} />
        <OvalSVG id="oval" />
        {isOpenCollage ? (
          <Collage type={type} setIsOpenCollage={setIsOpenCollage} />
        ) : (
          <p id="galerie" onClick={() => setIsOpenCollage(true)}>
            Galerie
          </p>
        )}
        <Services type={type} />
        <Feedback />
        <Footer />
      </section>
    </>
  );
};
