import { FC } from "react";
import "../styles/Home.scss";
import { Link } from "react-router-dom";
import { Footer } from "../components/Footer";
import { Header } from "../components/Header";

export const Home: FC = () => {
  return (
    <section id="home">
      <Header home={true} />
      <main>
        <h1>Wilkommen bei Montage Giesbrecht</h1>
        <h3>
          Wir sind ihr zuverlässiges Team für die Montage und Demontage von
          Kühlmöbeln , Kühlzellen und jeglicher Art von Ladenbau.
        </h3>
        <p>Erfahren Sie mehr…</p>
        <div>
          <Link to="/Kühlmöbel" data-title="Kühlmöbel">
            <img
              alt="refrigerated"
              src={process.env.PUBLIC_URL + "/images/refrigerated.png"}
            />
          </Link>
          <Link data-title="Kühlzelle" to="Kühlzelle">
            <img
              alt="coldroom"
              src={process.env.PUBLIC_URL + "/images/coldroom.png"}
            />
          </Link>
          <Link data-title="Ladenbau" to="Ladenbau">
            <img
              alt="Ladenbau"
              src={process.env.PUBLIC_URL + "/images/Ladenbau.png"}
            />
          </Link>
        </div>
      </main>
      <Footer />
    </section>
  );
};
