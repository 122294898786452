import { Dispatch, FC, SetStateAction, useState } from "react";
import { ReactComponent as CloseSVG } from "../assets/Close.svg";

export const Collage: FC<{
  type: string;
  setIsOpenCollage: Dispatch<SetStateAction<boolean>>;
}> = ({ type, setIsOpenCollage }) => {
  const [isClose, setIsClose] = useState<boolean>(false);

  function close() {
    setIsClose(true);
    setTimeout(() => setIsOpenCollage(false), 500);
  }

  return (
    <div id="collage" className={isClose ? "close" : "open"}>
      <CloseSVG id="collage-close" onClick={close} />
      <div id="collage-row-1">
        <img
          alt={type}
          src={process.env.PUBLIC_URL + `/images/${type}-1.png`}
        />
        <img
          alt={type}
          src={process.env.PUBLIC_URL + `/images/${type}-2.png`}
        />
        <img
          alt={type}
          src={process.env.PUBLIC_URL + `/images/${type}-3.png`}
        />
      </div>
      <div id="collage-row-2">
        <img
          alt={type}
          src={process.env.PUBLIC_URL + `/images/${type}-4.png`}
        />
        <img
          alt={type}
          src={process.env.PUBLIC_URL + `/images/${type}-5.png`}
        />
      </div>
      <div id="collage-row-3">
        <div>
          <img
            alt={type}
            src={process.env.PUBLIC_URL + `/images/${type}-6.png`}
          />
          <img
            alt={type}
            src={process.env.PUBLIC_URL + `/images/${type}-7.png`}
          />
        </div>
        <img
          alt={type}
          src={process.env.PUBLIC_URL + `/images/${type}-8.png`}
        />
      </div>
    </div>
  );
};
