import { FC } from "react";

export const Feedback: FC = () => {
  return (
    <form id="feedback" method="POST" action="/send">
      <h4>Sprechen Sie uns an</h4>
      <label>
        Vorname:
        <input type="text" name="name" required />
      </label>
      <label>
        Nachname:
        <input type="text" name="lastname" required />
      </label>
      <label>
        E-Mail:
        <input type="email" name="email" required />
      </label>
      <label>
        Nachricht:
        <textarea name="content" required></textarea>
      </label>
      <input
        name="path"
        style={{ display: "none" }}
        value={window.location.href}
      />
      <input type="submit" value="absenden" />
    </form>
  );
};
