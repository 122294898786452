import { FC } from "react";
import { ReactComponent as PhoneSVG } from "../assets/Phone.svg";
import { ReactComponent as EmailSVG } from "../assets/Email.svg";

export const Main: FC<{ type: string }> = ({ type }) => {
  return (
    <div id="main">
      <div>
        <p>
          <PhoneSVG /> 0179 7993018
        </p>
        <p>
          <PhoneSVG /> 029278006312
        </p>
        <p>
          <EmailSVG /> info@montage-giesbrecht.de
        </p>
      </div>
      <img alt={type} src={process.env.PUBLIC_URL + `/images/${type}-main.png`} />
    </div>
  );
};
