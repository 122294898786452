import { FC } from "react";
import { ReactComponent as CheckSVG } from "../assets/Check.svg";
import { ReactComponent as DeleteSVG } from "../assets/Delete.svg";
import { ReactComponent as DemontageSVG } from "../assets/Demontage.svg";
import { ReactComponent as MontageSVG } from "../assets/Montage.svg";
import { ReactComponent as RulerSVG } from "../assets/Ruler.svg";
import { ReactComponent as SettingSVG } from "../assets/Setting.svg";

export const Services: FC<{ type: string }> = ({ type }) => {
  return (
    <div id="services">
      <h2>Leistungen {type}</h2>
      <div>
        <p>
          <MontageSVG />
          Montage
        </p>
        <p>
          <DemontageSVG />
          Demontage
        </p>
        <p>
          <RulerSVG />
          {type === "Kühlzelle"
            ? "Zuverlässige Planung, Abmessungen"
            : "Fachgerechte Einbringung und Auspackung der Möbel"}
        </p>
        <p>
          <CheckSVG />
          {type === "Kühlzelle"
            ? "Ordnungsgemäße Demontage und Ausbringung"
            : "Fachgerechte Demontage und Ausbringung der Möbel"}
        </p>
        <p>
          <SettingSVG />
          {type === "Kühlzelle"
            ? "Ordentliche und Sorgfältige Montage"
            : "Kompetente und Sorgfältige Montage"}
        </p>
        <p>
          <DeleteSVG />
          Entsorgung
        </p>
      </div>
    </div>
  );
};
