import { FC } from "react";
import "../styles/Header.scss";
import { Link } from "react-router-dom";

export const Header: FC<{ home: boolean }> = ({ home }) => {
  return (
    <header id={home ? "" : "header-landing"}>
      <Link to="/"><img alt="logo" src={process.env.PUBLIC_URL + "/logo.png"} /></Link>
    </header>
  );
};
