import { FC } from "react";
import { Link } from "react-router-dom";

export const Footer: FC = () => {
  return (
    <footer>
      <div>
        <Link to="/Impressum">Impressum</Link>
        <Link to="/Datenschutzerklärung">Datenschutzerklärung</Link>
        <Link to="/Kontakt">Kontakt</Link>
      </div>
    </footer>
  );
};
