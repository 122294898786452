import { FC, useEffect } from "react";
import { Header } from "../components/Header";
import { Footer } from "../components/Footer";
import "../styles/Contact.scss";

export const Contact: FC = () => {
  useEffect(() => {
    window.scroll({ top: 0 });
  }, []);

  return (
    <>
      <Header home={false} />
      <section id="contact">
        <h1>Kontakt</h1>
        <div>
          <div>
            <p>Alexander Giesbrecht</p>
            <div>Geschäführung</div>
            <p>
              +49 179/7993018
              <br />
              +49 02927/8006312
              <br />
              alexander@montage-giesbrecht.de
            </p>
          </div>
          <div>
            <p>Helena Giesbrecht</p>
            <div>Buchhaltung</div>
            <p>
              +49 175/5952795
              <br />
              +49 02927/8006312
              <br />
              helena@montage-giesbrecht.de
            </p>
          </div>
        </div>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2480.009094426458!2d8.1822669!3d51.568066800000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47bbd7876c2f1297%3A0xcb91deead5328b10!2sAn%20d.%20Helle%201%2C%2059505%20Bad%20Sassendorf%2C%20Deutschland!5e0!3m2!1sde!2sby!4v1681567906081!5m2!1sde!2sby"
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
        <Footer />
      </section>
    </>
  );
};
